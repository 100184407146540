<template>
  <div>
    <Environment v-if="environment != 'production'"/>
    <Header class="trainingtopicheader"/>
    <div class="container-fluid quotecontainer">
      <div class="container quotetext"> 
        <div id="heading-training">
          <!-- <span class="largetext">Jacinta is an engaging and dynamic trainer</span> in the local,<br> national and international conflict resolution community.<br> She brings wisdom, insight, and tremendous knowledge to her teaching.  -->
          ACCESSING <span class="largetext">HOPE</span>.  MANAGING <span class="largetext">THREATS</span>.  BREATHING <span class="largetext">
            FRESH AIR</span>.
        </div>
        <div id="heading-team" class="hidden">
          Jacinta teams up with friends and colleagues to deliver <span class="largetext">interdisciplinary team training</span> that <br/>
          will help you launch or deepen your collaborative practice
        </div>
      </div>
    </div>

<div class="container-fluid textured" style="padding-top:50px; padding-bottom:50px;">     
  <div class="container" style="text-align:left;">
    <!--
    <div class="bubbleimage2">
      <img style="max-width:200px;" src="../assets/images/jacintaboard.png">
    </div>
    -->
    <h1>Training Topics</h1>
    <p>Collaborative Professionals, Lawyers, and Mediators need strong skills to handle complex information, 
      identify what matters most to our clients and help clients find creative, workable solutions to their conflicts. 
      We also need to be able to manage the emotional and psychological impacts of the conflict. At the core is the need 
      for insight into how conflict affects our clients and ourselves. 
    </p>
    <p>Jacinta is a dynamic, insightful conflict trainer, delivering professional development programs for collaborative 
      professionals, mediators and lawyers. </p>
    <p>Jacinta offers customized training for groups looking to develop skills for their particular areas of practice. 
      Please use the <a href="#form">form</a> below to enquire.  Some of her most popular course offerings are listed below, 
    but Jacinta is always expanding her knowledge and experience, so check out our 
    <a href="/Blog">Blog</a> or <a href="https://www.innovateforlawyers.com/#podcast" target="_blank">listen to her podcast</a> 
    to find out what’s new!  </p>
  </div>
</div>

<div class="container-fluid maincontentcontainer">     
    <div class="container" style="text-align:left;">
      <div class="row">
        <div class="col-md-8" style="padding-right:40px;">
          <h2>Deepening Without Drowning: Accessing Hope, Managing Threats & Breathing Fresh Air </h2>
          <!-- <img src="../assets/images/classcircle.png" style="float:right; margin-left:10px; margin-bottom:10px; max-width:200px;"> -->
          <p>Have you ever been stumped, and discouraged, by the continuing and sometimes escalating couple conflict at the collaborative 
            table - despite the sincere efforts of the professional team?  Do you sometimes feel like your "case" is drowning and you are 
            desperate for an oxygen tank?   </p>
          <p>In this workshop, we will learn how asking about the parties' hopes and diving deeply into their perceived threats can bring 
            fresh air to a conflict case. 
            Working with the "<a href="http://insightapproach.ca/" target="_blank">Insight Approach</a>" to conflict, we will learn to notice 
            and respond to conflict behaviours - rather than worrying about the facts and story lines fueling the conflict. We will learn how 
            and when to ask questions to unearth the threats that escalate conflict, without provoking it, and how to use what is learned to 
            promote an authentic shift from conflict to collaboration, without forcing or faking it. </p>
          <p>Participants will learn: </p>
          <ul>
            <li>New ways of thinking about interpersonal conflict </li>
            <li>New skills to notice and intervene in conflict (early and often)</li>
            <li>How to help clients talk about what matters in ways that do not threaten.  </li>
            <li>How to ask about hopes in a productive and meaningful way, and to work with the responses to help manage threats </li>
            <li>How to stop talking about what <b>we think</b> the conflict is about - and to stop talking about what <b>we think</b> matters 
            to the parties - 
            and instead to be curious and ask good questions to help parties discover this for themselves </li>
            <li>How to do all of this while facilitating a process leading to agreement! </li>
          </ul>
          <h2>Shifting from Judgment to Curiosity in Collaborative Practice </h2>
          <!-- <img src="../assets/images/classcircle2.png" style="float:left; margin-right:15px; margin-bottom:10px; max-width:200px;"> -->
          <p>Collaborative professionals and mediators have to manage the substantive, procedural and psychological aspects of our clients’ 
            separation.  Our clients enter the process with feelings of hurt, anger and defensiveness. They often have a very narrow view of 
            what is possible. Judgments and assumptions abound. Our job is to help them move through their hurt, anger and defensiveness and 
            open themselves up to the many possibilities for the future.   </p>
          <p>Conflict professionals, whether in collaborative practice or mediation, have tremendous knowledge and experience. 
          We need to be aware that we, just as much as our clients, are influenced by our own judgments and assumptions. 
          In our professional roles, we are applauded for sound judgment and strong analytical skills.  
          We see so many conflicts with similar patterns, it’s hard not to fall into the trap of thinking <b><i>we know</i></b> what is going on.  
          Yet, we don’t  – and staying open and curious is vital if we want to help our clients discover for themselves what is going on and 
          what matters. Curiosity calms and calm people are more open to the insights needed to effectively deal with conflict. So this course 
          will help you to cultivate curiosity and calm to be more productive and insightful in your conflict work.  
          </p>
          <h2>The Art of Asking Questions </h2>
          <!-- <img style="max-width:200px; float:right; margin-left:15px; margin-bottom:15px;" src="<?=ROOT?>images/Jacinta-084circle.png" title="Jacinta Gallant Collaborative Lawyer and Mediator" alt="Jacinta Gallant Training &amp; Presentations in Collaborative Law"> -->
          <p>Conflict resolution professionals need strong communications skills and deep insight in order to help our clients get to the root 
            of their problems. Asking questions is one of the vital communication skills in our conflict resolution toolbox. We ask questions 
            to aid in understanding. We <i>intend</i> to be helpful and open, so when we get a defensive or resistant reaction, we wonder what 
            went wrong?  In some instances, our well-intentioned questions lead down the path of “factual enquiry” about “what happened?” - which 
            is a great lead-in to the “Blame Game”.  
          Other times, our questions can lead to feelings of frustration as we steer the conversation where <i>we want</i> it to go, either because 
          we are missing the cues that allow us to follow the client’ lead, or because we are uncomfortable with the direction the conversation is 
          taking and we <i>do not want to be uncomfortable</i>. There are so many ways our questions can impact conflict and understanding, it’s no 
          wonder many of us are apprehensive about what to ask and how to ask it. </p>
          <p>Hopefully, we have all felt the joy of a question that led to <i>brilliant, illuminating insight</i>!  This workshop is about how can 
          we do this more. We will challenge ourselves to consider: </p>
          <ul>
            <li>What kinds of questions aid in understanding? </li>
            <li>What should we be asking <i>about</i>? </li>
            <li><i>Who</i> should be asking the questions? </li>
            <li>What judgments are hidden (or apparent!) in our questions? </li>
            <li>How to use questioning to find creative solutions. </li>
          </ul>
          <h2>Reaching Agreement with a Focus on What Matters </h2>
          <!-- <img src="../assets/images/classcircle3.png" style="float:right; margin-left:10px; margin-bottom:10px; max-width:200px;"> -->
        <p>After all the good work exploring what matters to the parties, how do we make sure we don’t lose what has been learned when we begin to 
          generate options for agreement? 
          Separating couples are facing complex family decisions that will have a lasting impact on their family, and they want our help.  Sometimes, 
          this stage of the process starts to feel like “bargaining” and we see conflict and positions resurface.  This course will focus on how to 
          generate creative, workable options that focus on what matters to the parties, and how to appropriately offer our help and expertise, without 
          leading the clients down the path of what <b>we think</b> they should do.  </p>
          <p>The key to this stage of the process is to re-engage curiosity and formulate good questions that incorporate what has been learned and 
            what matters.  It is very likely that some of the parties’ concerns have been resolved and that some new challenges have arisen. This course 
            will teach you how to formulate the questions that need to be asked in order for the parties to make good decisions and reach agreement. You 
            will learn how to create a <b>Decision-making Agenda</b> to organize what matters into a workable set of questions that will task the parties 
            and conflict professionals in option-generation, and provide a criteria for evaluating and choosing options  based on what matters to the 
            parties.  </p>
          <h2>Introduction To Interdisciplinary Collaborative Practice</h2>
        <p>Jacinta is a member of the <a href="https://www.collaborativepractice.com/introductory-collaborative-practice-training" target="_blank">IACP</a>
        Faculty offering interdisciplinary collaborative practice training around the world and teaches an 
        introductory course every summer as part of <a href="/SummerSessions">Summer Sessions</a>. </p>
        </div>
        <div class="col-md-4">
          <div class="row"  style="text-align:center;">
          <br/><br/>
          <a class="gotobutton" href="#form" title="For Training Inquiries Click Here">Make Training Inquiry</a>
          <br/>
          </div>
          <div class="row" style="border-left:2px dashed #837c72;">
          <br>
          <h2 style="text-align:center;">Training Feedback</h2>


              <div class="col-xs-12  testimonialfont">
                <i class="fa fa-quote-left quotemark" aria-hidden="true"></i>
                <i>
                  I came away from Jacinta Gallant's training energized, enthusiastic about having deepened my skill set, and 
                  deeply impressed with her authentic, warm, informed and engaging presence.  I highly recommend this training.
                  <span class="line"> <i class="fa fa-quote-right quotemark" aria-hidden="true"></i></span>
                  <br><br>
                  <!-- <span class="pull-right" style="text-align:right;">-Deborah Clemmensen, M.Eq., LP.</span> -->
                  <span class="pull-right" style="text-align:right;"><small><i>Deepening Without Drowning: Accessing Hope, 
                    Managing Threats & Breathing Fresh Air</i><br/> Minneapolis 2019</small></span>
                </i>
                
              </div>
              <div class="col-xs-12  testimonialfont2">
              <i class="fa fa-quote-left quotemark" aria-hidden="true"></i>
                <i>
                Definitely make the time to attend Jacinta’s workshops. She is a charismatic and insightful speaker.  Learning 
                from her experience is completely essential if you practice collaborative law.
                <span class="line"> <i class="fa fa-quote-right quotemark" aria-hidden="true"></i></span>
                </i>
                <br><br>
                <span class="pull-right" style="text-align:right;"><small><i>Shifting from Judgment to Curiosity</i><br/> 
                Perth, Scotland 2017 </small></span>
            </div>
            <div class="col-xs-12  testimonialfont">
                <i class="fa fa-quote-left quotemark" aria-hidden="true"></i>
                <i>
                Your presentation was really wonderful.  It shed a higher level light to our current practice.  So many times 
                we attend a presentation and walk away thinking, “o.k., I’m doing most of that.  I should or may be able to 
                tweak this or that.”  In this case, I and many of my peers were really moved by your presentation!
                <span class="line"> <i class="fa fa-quote-right quotemark" aria-hidden="true"></i></span>
                  <br><br>
                  <!-- <span class="pull-right" style="text-align:right;">-Deborah Clemmensen, M.Eq., LP.</span> -->
                  <span class="pull-right" style="text-align:right;"><small><i>Shifting from Judgment to Curiosity</i> 
                  <br/>Collaborative Professionals of Washington 2017   </small></span>
                </i>
              </div>
              <div class="col-xs-12  testimonialfont2">
              <i class="fa fa-quote-left quotemark" aria-hidden="true"></i>
                <i>
                Out of all of the presentations that I attended at the IACP Forum, yours was by far my favorite.  I was so 
                excited to share with my fellow collaborative practitioners the concept of using the interest agenda to 
                create solutions to meet our clients’ interests.  I believe that this will also help “balance” certain power 
                struggles we encounter during the process by ensuring that even the less vocal party’s interests are put 
                forth and considered equally as those put forth by the more vocal individual.  It forces the professionals to 
                work with clients to identify what their real interests are, going much deeper than what the clients may 
                initially put forth as more position-oriented needs.
                <span class="line"> <i class="fa fa-quote-right quotemark" aria-hidden="true"></i></span>
                </i>
                <br><br>
                <span class="pull-right" style="text-align:right;"><small><i>Keeping Interests on the Table</i> <br/> IACP Forum 2016</small></span>
                <!-- <span class="pull-right" style="text-align:right;"><small><i>"Shifting from Judgment to Curiosity" </i></small></span> -->
            </div>
        </div>
      </div>
      <div id="form" class="col-xs-12 dotted-border"></div>
        <TrainingRequestForm />
</div>
<!-- Add for here -->
    </div>
</div>
    <Footer/>
  </div>
</template>

<script>
// Components
import Header from '../components/Header.vue'
import TrainingRequestForm from '../components/TrainingRequestForm.vue'
import Footer from '../components/Footer.vue'

export default {
  name: 'Training Presentations',
  components: {
    Header,
    TrainingRequestForm,
    Footer
  },
  setup() {
    const environment = process.env.VUE_APP_ENVIRONMENT

    return {
      environment
    }
  }
}
</script>

<style lang="scss">
  
</style>
